import { MenuIcon } from "@/svgs";
import styled from "@emotion/styled";

export const AnimatedMenuIcon = styled(MenuIcon, {
  shouldForwardProp: (prop) => prop !== "openned",
})<{
  openned?: boolean;
}>`
  width: 16px;
  height: 16px;

  & > path {
    transition: all 0.3s ease-in-out;
  }

  & > path:nth-of-type(1) {
    transform-origin: 50% 29.17%;
    transform: translate(0, 0) rotate(0);
  }

  &.menu-openned > path:nth-of-type(1) {
    transform: translate(0.25px, 3.25px) rotate(45deg);
  }

  & > path:nth-of-type(2) {
    transform-origin: center center;
    opacity: 1;
    transform: scaleX(1);
  }

  &.menu-openned > path:nth-of-type(2) {
    opacity: 0;
    transform: scaleX(0);
  }

  & > path:nth-of-type(3) {
    transform-origin: 50% 70.83%;
    transform: translate(0, 0) rotate(0);
  }

  &.menu-openned > path:nth-of-type(3) {
    transform: translate(0.25px, -3.25px) rotate(-45deg);
  }
`;
