import { SectionSubtitle } from "@/components/SectionSubtitle";
import { SectionTitle } from "@/components/SectionTitle";
import { useMuiTheme } from "@/hooks/useMuiTheme";
import { Box, Button, Container, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

export const DownloadAppSection = () => {
  const theme = useMuiTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        py: 8,
        mt: 8,
        [theme.breakpoints.down(1324)]: {
          mt: 0,
        },
        [theme.breakpoints.down("sm")]: {
          pt: 2,
        },
      }}
    >
      <Container>
        <Box
          sx={{
            width: "100%",
            position: "relative",
            display: "grid",
            gridTemplateColumns: "minmax(0, 1fr) minmax(0, 608px)",
            borderRadius: "96px 16px 90px",
            columnGap: 4,
            backgroundColor: "primary.100",
            [theme.breakpoints.down(1324)]: {
              gridTemplateColumns: "1fr",
              gridTemplateRows: "auto auto",
              justifyItems: "center",
            },
            px: {
              xs: 0,
              md: 4,
              lg: 6,
              xl: 8,
            },
          }}
        >
          <Box
            sx={{
              position: "static",
              pt: 8,
              pb: {
                xs: 2,
                md: 4,
                xl: 8,
              },
            }}
          >
            <SectionTitle
              color="primary.700"
              sx={{ [theme.breakpoints.down("xl")]: { textAlign: "center" } }}
            >
              {t("home-download.title")}
            </SectionTitle>
            <SectionSubtitle
              color="translucent.black.88"
              sx={{ mt: 1, [theme.breakpoints.down("xl")]: { textAlign: "center" } }}
            >
              {t("home-download.subtitle")}
            </SectionSubtitle>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2 }}
              alignItems="center"
              mt={3}
            >
              <Button
                component="a"
                href="https://play.google.com/store/apps/details?id=com.genfosis.dnacanvas"
                target="_blank"
                sx={{ width: "auto", height: 56, p: 0 }}
              >
                <Box
                  component="img"
                  src="/assets/imgs/webp/google-play-button.webp"
                  alt="Google Play download button"
                  sx={{ width: "auto", height: 56 }}
                />
              </Button>
              <Button
                component="a"
                href="https://apps.apple.com/th/app/dnacanvas/id1572939524"
                target="_blank"
                sx={{ width: "auto", height: 56, p: 0 }}
              >
                <Box
                  component="img"
                  src="/assets/imgs/webp/app-store-button.webp"
                  alt="App Store download button"
                  sx={{ width: "auto", height: 56 }}
                />
              </Button>
              {/* <Box
                component="img"
                src="/assets/imgs/webp/download-qr.webp"
                sx={{
                  width: 56,
                  height: 56,
                }}
              /> */}
            </Stack>
          </Box>
          <Box
            component="img"
            src="/assets/imgs/webp/download-app.webp"
            alt="Download app example image"
            sx={{
              width: "100%",
              position: "absolute",
              right: 64,
              bottom: 0,
              maxWidth: 608,
              [theme.breakpoints.down(1324)]: {
                position: "static",
              },
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};
