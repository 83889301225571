import { useMuiTheme } from "@/hooks/useMuiTheme";
import { Box, Container, Stack, Typography, useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

import { EndorsementCard } from "@/components/OrganizationCard";
import { useTranslation } from "react-i18next";

export const QualityGuaranteedSection = () => {
  const theme = useMuiTheme();

  const upLg = useMediaQuery(theme.breakpoints.up("lg"));
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        py: 8,
        backgroundColor: "info.100",
      }}
    >
      <Container
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          columnGap: 2,
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "repeat(4,1fr)",
          },
        }}
        disableGutters={true}
      >
        <Box
          sx={{
            gridColumn: "2 / span 10",
            [theme.breakpoints.down("2xl")]: {
              gridColumn: "1 / -1",
            },
          }}
        >
          <Typography
            variant="h3"
            textAlign="center"
            sx={{
              px: 2,
              typography: {
                xs: "h3",
                sm: "h2",
              },
            }}
          >
            {t("home-quality.title")}
          </Typography>
          <Typography
            variant="caption5"
            color="translucent.black.56"
            sx={{
              typography: {
                sm: "caption3",
              },
              mt: 1,
            }}
            textAlign="center"
            whiteSpace="pre-line"
          >
            {t("home-quality.subtitle", {
              lineBreak: downSm ? "\n" : "",
            })}
          </Typography>
          <Stack
            width="100%"
            alignItems="center"
            direction={{
              xs: "column",
              lg: "row",
            }}
            spacing={5}
            // TODO: I made this up, need to check with design
            mt={{
              xs: 3,
              sm: 4,
              md: 5,
              lg: 6,
              xl: 7,
            }}
            px={{
              xs: 0,
              lg: 4,
              "2xl": 0,
            }}
          >
            <Box
              component="img"
              src={"/assets/imgs/webp/quality-guaranteed.webp"}
              alt={`Quality assured decoration image`}
              sx={{
                display: "block",
                width: "25vw",
                height: "100%",
                aspectRatio: "1 / 1",
                maxWidth: 408,
                minWidth: 375,
                [theme.breakpoints.down("lg")]: {
                  width: "100%",
                },
                [theme.breakpoints.down(408)]: {
                  maxWidth: "unset",
                },
              }}
            />
            {upLg ? (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: 2,
                }}
                width="100%"
              >
                <Stack spacing={2} justifyContent="center">
                  <EndorsementCard
                    image="/assets/imgs/webp/organizations/fda.webp"
                    title={t("home-quality.fda.title")}
                    description={t("home-quality.fda.description")}
                  />
                  <EndorsementCard
                    image="/assets/imgs/webp/organizations/dip.webp"
                    title={t("home-quality.dip.title")}
                    description={t("home-quality.dip.description")}
                  />
                  <EndorsementCard
                    image="/assets/imgs/webp/organizations/bsi.webp"
                    title={t("home-quality.bsi.title")}
                    description={t("home-quality.bsi.description")}
                  />
                  <EndorsementCard
                    image="/assets/imgs/webp/organizations/clia.webp"
                    title={t("home-quality.clia.title")}
                    description={t("home-quality.clia.description")}
                  />
                  {/* <EndorsementCard
                    image="/assets/imgs/webp/organizations/china-export.webp"
                    title={t("home-quality.china-export.title")}
                    description={t("home-quality.china-export.description")}
                  /> */}
                </Stack>
                <Stack spacing={2} justifyContent="center">
                  <EndorsementCard
                    image="/assets/imgs/webp/organizations/iso.webp"
                    title={t("home-quality.iso.title")}
                    description={t("home-quality.iso.description")}
                  />
                  <EndorsementCard
                    image="/assets/imgs/webp/organizations/european-conformity.webp"
                    title={t("home-quality.european-conformity.title")}
                    description={t("home-quality.european-conformity.description")}
                  />
                  <EndorsementCard
                    image="/assets/imgs/webp/organizations/cap.webp"
                    title={t("home-quality.cap.title")}
                    description={t("home-quality.cap.description")}
                  />
                </Stack>
              </Box>
            ) : (
              <Container maxWidth={false} sx={{ px: 2, maxWidth: "100vw" }}>
                <Swiper
                  slidesPerView={1.25}
                  spaceBetween={16}
                  breakpoints={{
                    // [theme.breakpoints.values.xs]: {
                    //   grid: {
                    //     rows: 2,
                    //   },
                    //   slidesPerView: 2,
                    // },
                    [theme.breakpoints.values.sm]: {
                      slidesPerView: 2.25,
                    },
                    [theme.breakpoints.values.md]: {
                      slidesPerView: 2.5,
                    },
                  }}
                  style={{ overflow: "visible" }}
                >
                  <SwiperSlide>
                    <Stack spacing={2} justifyContent="center">
                      <EndorsementCard
                        image="/assets/imgs/webp/organizations/fda.webp"
                        title={t("home-quality.fda.title")}
                        description={t("home-quality.fda.description")}
                      />
                      <EndorsementCard
                        image="/assets/imgs/webp/organizations/dip.webp"
                        title={t("home-quality.dip.title")}
                        description={t("home-quality.dip.description")}
                      />
                    </Stack>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Stack spacing={2} justifyContent="center">
                      <EndorsementCard
                        image="/assets/imgs/webp/organizations/bsi.webp"
                        title={t("home-quality.bsi.title")}
                        description={t("home-quality.bsi.description")}
                      />
                      {/* <EndorsementCard
                        image="/assets/imgs/webp/organizations/china-export.webp"
                        title={t("home-quality.china-export.title")}
                        description={t("home-quality.china-export.description")}
                      /> */}
                      <EndorsementCard
                        image="/assets/imgs/webp/organizations/clia.webp"
                        title={t("home-quality.clia.title")}
                        description={t("home-quality.clia.description")}
                      />
                    </Stack>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Stack spacing={2} justifyContent="center">
                      <EndorsementCard
                        image="/assets/imgs/webp/organizations/iso.webp"
                        title={t("home-quality.iso.title")}
                        description={t("home-quality.iso.description")}
                      />
                      <EndorsementCard
                        image="/assets/imgs/webp/organizations/cap.webp"
                        title={t("home-quality.cap.title")}
                        description={t("home-quality.cap.description")}
                      />
                    </Stack>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Stack spacing={2} justifyContent="center">
                      <EndorsementCard
                        image="/assets/imgs/webp/organizations/european-conformity.webp"
                        title={t("home-quality.european-conformity.title")}
                        description={t("home-quality.european-conformity.description")}
                      />
                    </Stack>
                  </SwiperSlide>
                </Swiper>
              </Container>
            )}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};
