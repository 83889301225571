import { AnimatedMenuIcon } from "@/components/AnimatedHamburgerIcon";
import { NavbarLinkButton } from "@/components/NavbarLinkButton";
import { SwitchLanguageButton } from "@/components/SwitchLanguageButton";
import { useMuiTheme } from "@/hooks/useMuiTheme";
import { DNACanvasLogo } from "@/svgs";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Stack,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";
import NextLink from "next/link";
import { Fragment, useState } from "react";

import type { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {}

export const Navbar: FC<Props> = () => {
  const theme = useMuiTheme();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isScrolled = useScrollTrigger({ threshold: 0, disableHysteresis: true });
  const { t } = useTranslation();

  return (
    <Fragment>
      <Toolbar sx={{ gridArea: "navbar" }} />
      <AppBar
        sx={(theme) => ({
          background: "white",
          height: `${theme.mixins.toolbar.height}px`,
          boxShadow: isScrolled ? theme.palette.boxShadow[200] : "none",
        })}
      >
        <Container
          sx={{
            height: "100%",
            display: "grid",
            gridTemplateColumns: "171px 1fr",
            gridTemplateRows: "1fr",
            placeItems: "center",
            columnGap: 2,
          }}
        >
          <NextLink href="/" aria-label="Home Page" style={{ display: "inline-flex" }}>
            <DNACanvasLogo width={171} height={null} />
          </NextLink>
          <Box
            width="100%"
            display="flex"
            justifyContent="flex-end"
            sx={{
              display: {
                xs: "flex",
                md: "none",
              },
            }}
          >
            <Button
              aria-label={isSidebarOpen ? "Close sidebar" : "Open sidebar"}
              sx={{
                placeSelf: "end",
                alignSelf: "center",
                width: 32,
                height: 32,
                backgroundColor: "primary.100",
                borderRadius: 8,
                ":hover": {
                  backgroundColor: "primary.100",
                },
              }}
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              <AnimatedMenuIcon
                className={isSidebarOpen && "menu-openned"}
                width={16}
                height={16}
              />
            </Button>
            <Drawer
              anchor="right"
              open={isSidebarOpen}
              sx={{
                position: "fixed",
                inset: 0,
                top: theme.mixins.toolbar.height,
              }}
              PaperProps={{
                sx: {
                  position: "absolute",
                  inset: 0,
                  backgroundColor: "white.main",
                  boxShadow: "none",
                  p: 2,
                },
              }}
              hideBackdrop
            >
              <Stack
                spacing={3}
                alignItems="center"
                divider={<Divider flexItem sx={{ borderColor: "gray.100" }} />}
              >
                <NavbarLinkButton href="why-dnacanvas" label={t("why-dnacanvas")} noBorder />
                <NavbarLinkButton href="about" label={t("about-us")} noBorder />
                <NavbarLinkButton href="products" label={t("purchase-dna-testkit")} noBorder />
                <SwitchLanguageButton />
              </Stack>
            </Drawer>
          </Box>
          <Box
            sx={(_theme) => ({
              height: "100%",
              display: {
                xs: "none",
                md: "grid",
              },
              gridTemplateColumns: "repeat(4, minmax(0, auto))",
              gridTemplateRows: "1fr",
              placeItems: "center",
              placeSelf: "end",
              columnGap: 8,
              [theme.breakpoints.down("xl")]: {
                columnGap: 4,
              },
              [theme.breakpoints.down("lg")]: {
                columnGap: 2,
              },
            })}
          >
            <NavbarLinkButton href="why-dnacanvas" label={t("why-dnacanvas")} />
            <NavbarLinkButton href="about" label={t("about-us")} />
            <NavbarLinkButton href="products" label={t("purchase-dna-testkit")} />
            {/* <NextLink href="" legacyBehavior passHref>
                <Link variant="label2" color="translucent.black.88">
                  ลูกค้าองค์กร
                </Link>
              </NextLink> */}
            <SwitchLanguageButton />
          </Box>
        </Container>
      </AppBar>
    </Fragment>
  );
};

export default Navbar;
