import { IconWrapper } from "@/components/IconWrapper";
import { useMuiTheme } from "@/hooks/useMuiTheme";
import {
  CallIcon,
  DNACanvasLogoWhite,
  FacebookIcon,
  InstagramIcon,
  LineIcon,
  SmsIcon,
} from "@/svgs";
import { Box, Container, Link, Stack, Typography } from "@mui/material";
import NextLink from "next/link";
import { useTranslation } from "react-i18next";

import type { BoxProps } from "@mui/material";
import type { FC } from "react";

interface Props extends BoxProps {}

export const Footer: FC<Props> = ({ ...boxProps }) => {
  const theme = useMuiTheme();

  const { t } = useTranslation();

  return (
    <Box
      {...boxProps}
      sx={{
        ...boxProps?.sx,
        gridArea: "footer",
      }}
    >
      <Box sx={{ borderTopLeftRadius: 120, backgroundColor: "common.darkblue" }}>
        <Container
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(12,1fr)",
            columnGap: 2,
            [theme.breakpoints.down("md")]: {
              gridTemplateColumns: "repeat(4,1fr)",
            },
          }}
        >
          <Box
            sx={{
              gridColumn: "2 / span 10",
              py: 6,
              [theme.breakpoints.down("xl")]: {
                gridColumn: "1 / -1",
              },
              [theme.breakpoints.down("md")]: {
                px: 2,
              },
            }}
          >
            <Box
              display="grid"
              sx={{
                gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateRows: "56px 1fr",
                rowGap: 3,
                [theme.breakpoints.down("lg")]: {
                  gridTemplateColumns: "1fr",
                  rowGap: 4,
                },
              }}
            >
              <Box
                gridColumn="1 / span 2"
                sx={{
                  [theme.breakpoints.down("lg")]: {
                    gridColumn: "1 / span 1",
                  },
                }}
              >
                <DNACanvasLogoWhite width={239} height={56} />
              </Box>
              <Box>
                <Typography variant="body2" color="gray.500" sx={{ maxWidth: 480, mb: 3 }}>
                  {`101 Pongtheerathon Building, Soi Rama IX 60 (Soi 7 Seree 7), Rama 9 Road, Phatthanakan, Suanluang, Bangkok 10250`}
                </Typography>
                <Stack spacing={2}>
                  <Box
                    component="a"
                    href="https://lin.ee/2AwJa7x"
                    target="_blank"
                    rel="noreferrer nofollow"
                    display="flex"
                    alignItems="center"
                  >
                    <IconWrapper rounded sx={{ mr: 1.5 }}>
                      <LineIcon width={16} height={16} />
                    </IconWrapper>
                    <Link component="span" variant="label2" color="gray.500">
                      @DNAcanvas
                    </Link>
                  </Box>
                  <Box component="a" href="tel:061-656-6047" display="flex" alignItems="center">
                    <IconWrapper rounded sx={{ mr: 1.5 }}>
                      <CallIcon width={16} height={16} />
                    </IconWrapper>
                    <Link component="span" variant="label2" color="gray.500">
                      061-656-6047
                    </Link>
                  </Box>
                  <Box
                    component="a"
                    href="mailto:contact@DNAcanvas.co"
                    display="flex"
                    alignItems="center"
                  >
                    <IconWrapper rounded sx={{ mr: 1.5 }}>
                      <SmsIcon width={16} height={16} />
                    </IconWrapper>
                    <Link component="span" variant="label2" color="gray.500">
                      contact@DNAcanvas.co
                    </Link>
                  </Box>
                </Stack>
              </Box>
              <Box
                display="grid"
                gridTemplateColumns="repeat(5, 1fr)"
                sx={{
                  [theme.breakpoints.down("lg")]: {
                    gridTemplateColumns: "repeat(4, 1fr)",
                  },
                }}
              >
                <Stack
                  spacing={2}
                  sx={{
                    gridColumnStart: 2,
                    gridColumnEnd: "span 2",
                    [theme.breakpoints.down("lg")]: {
                      gridColumnStart: 1,
                    },
                  }}
                >
                  <NextLink href="/" legacyBehavior passHref>
                    <Link variant="caption4" color="info.100">
                      {t("homepage")}
                    </Link>
                  </NextLink>
                  <NextLink href="/why-dnacanvas" legacyBehavior passHref>
                    <Link variant="caption4" color="info.100">
                      {t("why-dnacanvas")}
                    </Link>
                  </NextLink>
                  <NextLink href="/about" legacyBehavior passHref>
                    <Link variant="caption4" color="info.100">
                      {t("about-us")}
                    </Link>
                  </NextLink>
                  <NextLink href="/products" legacyBehavior passHref>
                    <Link variant="caption4" color="info.100">
                      {t("purchase-products")}
                    </Link>
                  </NextLink>
                </Stack>
                <Stack
                  spacing={2}
                  sx={{
                    gridColumnStart: 4,
                    gridColumnEnd: "span 2",
                    [theme.breakpoints.down("lg")]: {
                      gridColumnStart: 3,
                    },
                  }}
                >
                  <NextLink href="/privacy-policy" legacyBehavior passHref>
                    <Link variant="caption4" color="info.100">
                      {t("privacy-policy")}
                    </Link>
                  </NextLink>
                  <NextLink href="/terms-of-use" legacyBehavior passHref>
                    <Link variant="caption4" color="info.100">
                      {t("terms-of-use")}
                    </Link>
                  </NextLink>
                  {/* <NextLink href="/faq" legacyBehavior passHref>
                    <Link variant="caption4" color="info.100">
                      {t("faq")}
                    </Link>
                  </NextLink> */}
                </Stack>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "#000", color: "white.main" }}>
        <Container
          sx={{
            height: "100%",
            py: 2,
            display: "grid",
            gridTemplateColumns: "repeat(12,1fr)",
            columnGap: 2,
            [theme.breakpoints.down("md")]: {
              gridTemplateColumns: "repeat(4,1fr)",
            },
          }}
          // disableGutters={upXl}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
            sx={{
              gridColumn: "2 / span 10",
              [theme.breakpoints.down("xl")]: {
                gridColumn: "1 / -1",
              },
              [theme.breakpoints.down("md")]: {
                px: 2,
              },
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <Stack direction="row" spacing={3}>
              <NextLink
                href="https://www.facebook.com/DNAcanvas/"
                target="_blank"
                aria-label="Link to Facebook"
              >
                <FacebookIcon width={24} height={24} />
              </NextLink>
              <NextLink
                href="https://www.instagram.com/dnacanvas_official/"
                target="_blank"
                aria-label="Link to Instagram"
              >
                <InstagramIcon width={24} height={24} />
              </NextLink>
            </Stack>
            <Typography variant="body2" color="info.100" mt={{ xs: 1, sm: 0 }}>
              © 2021 DNAcanvas. All rights reserved
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
