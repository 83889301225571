import Footer from "@/views/common/Footer";
import Navbar from "@/views/common/Navbar";
import { Box } from "@mui/material";

import type { FC, PropsWithChildren } from "react";

interface Props {
  backgroundColor?: string;
  contentBackgroundColor?: string;
  footerBackgroundColor?: string;
}

export const MainLayout: FC<PropsWithChildren<Props>> = ({
  backgroundColor = "background.default",
  contentBackgroundColor,
  footerBackgroundColor,
  children,
}) => {
  return (
    <Box
      sx={(theme) => ({
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: `${theme.mixins.toolbar.height}px 1fr auto`,
        gridTemplateColumns: "1fr",
        gridTemplateAreas: `
        "navbar"
        "main"
        "footer"
        `,
      })}
    >
      <Navbar />
      <Box sx={{ gridArea: "main", backgroundColor: contentBackgroundColor || backgroundColor }}>
        {children}
      </Box>
      <Footer
        sx={{ gridArea: "footer", backgroundColor: footerBackgroundColor || backgroundColor }}
      />
    </Box>
  );
};

export default MainLayout;
