import prompt from "@/fonts/prompt";
import { Avatar, Paper, Typography, type PaperProps } from "@mui/material";
import { useMemo, type FC } from "react";
import { useTranslation } from "react-i18next";

interface Props extends PaperProps {
  image: string;
  text: string;
  name: string;
  occupations: string | string[];
  age: number;
}
export const UserReviewCard: FC<Props> = ({ image, text, name, occupations, age, ...props }) => {
  const { t, i18n } = useTranslation();

  const listFormat = useMemo(() => {
    return new Intl.ListFormat(i18n.language, { style: "long" });
  }, [i18n.language]);

  const occupationText =
    typeof occupations === "string" ? occupations : listFormat.format(occupations);

  return (
    <Paper {...props} sx={{ p: 3, boxSizing: "border-box", borderRadius: 16, ...props?.sx }}>
      <Avatar src={image} alt={name} sx={{ width: 104, height: 104 }} />
      <Typography variant="body2" color="translucent.black.88" sx={{ mt: 2 }}>
        {text}
      </Typography>
      <Typography
        fontFamily={prompt.style.fontFamily}
        fontSize={12}
        lineHeight={1.5}
        fontWeight={600}
        sx={{ mt: 2 }}
      >
        {name}
      </Typography>
      <Typography
        fontFamily={prompt.style.fontFamily}
        fontSize={10}
        lineHeight={1.8}
        fontWeight={400}
      >
        {occupationText}, {age} {t("age.suffix")}
      </Typography>
    </Paper>
  );
};
