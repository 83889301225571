import { useMuiTheme } from "@/hooks/useMuiTheme";
import styled from "@emotion/styled";
import { Box, Container, Typography } from "@mui/material";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { LifestylePackageCard } from "../products/LifestylePackageCard";
import { PlusPackageCard } from "../products/PlusPackageCard";
import { PremiumPackageCard } from "../products/PremiumPackageCard";

const StyledSwiper = styled(Swiper)`
  overflow: visible;

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    display: flex;
    height: auto;
  }

  // ${({ theme }) => theme.breakpoints.down("xl")} {
  //   overflow: visible;
  // }
`;

export const PackageSection = () => {
  const theme = useMuiTheme();
  const { t } = useTranslation();
  return (
    <Box id="package" sx={{ py: 8 }}>
      <Container
        sx={{
          isolation: "isolate",
          position: "relative",
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          columnGap: 2,
        }}
      >
        <Box
          sx={{
            gridColumn: "2 / span 10",
            [theme.breakpoints.down("2xl")]: {
              gridColumn: "span 12",
            },
          }}
        >
          <Typography variant="h2" textAlign="center">
            {parse(t("home-package.title"))}
          </Typography>
          <Box
            sx={{
              mt: {
                xs: 3,
                md: 5,
                lg: 7,
              },
            }}
          >
            <StyledSwiper
              slidesPerView={1.15}
              spaceBetween={16}
              // centeredSlides={true}
              // centeredSlidesBounds={true}
              breakpoints={{
                480: {
                  slidesPerView: 1.4,
                },
                [theme.breakpoints.values.sm]: {
                  slidesPerView: 1.75,
                },
                [theme.breakpoints.values.md]: {
                  spaceBetween: 24,
                  slidesPerView: 2.4,
                },
                [theme.breakpoints.values.lg]: {
                  spaceBetween: 32,
                  slidesPerView: 3,
                },
                [theme.breakpoints.values.xl]: {
                  slidesPerView: 3,
                },
              }}
            >
              <SwiperSlide>
                <LifestylePackageCard />
              </SwiperSlide>
              <SwiperSlide>
                <PlusPackageCard />
              </SwiperSlide>
              <SwiperSlide>
                <PremiumPackageCard />
              </SwiperSlide>
            </StyledSwiper>
          </Box>
        </Box>
        <Box
          sx={{
            width: 835,
            height: 835,
            position: "absolute",
            left: "100%",
            top: -238,
            transform: "translate(-250px, 0)",
            zIndex: -1,
            [theme.breakpoints.down("lg")]: {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              width: 835,
              height: 835,
              border: "125px solid",
              borderColor: "mint.100",
              borderRadius: 999,
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};
