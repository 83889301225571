import { Typography, type TypographyProps } from "@mui/material";
import merge from "lodash/merge";

import type { FC, PropsWithChildren } from "react";

export const SectionSubtitle: FC<PropsWithChildren<TypographyProps>> = (props) => {
  return (
    <Typography
      variant="caption5"
      color="translucent.black.56"
      {...props}
      sx={merge({ typography: { sm: "caption3" } }, props?.sx)}
    />
  );
};
