import MainLayout from "@/layouts/MainLayout";

import type { NextPage } from "next";
import { BenefitsSection } from "../BenefitsSection";
import { DownloadAppSection } from "../DownloadAppSection";
import { HeadingSection } from "../HeadingSection";
import { PackageSection } from "../PackageSection";
import { PartnersSections } from "../PartnersSections";
import { QualityGuaranteedSection } from "../QualityGuaranteedSection";
import { StepsSection } from "../StepsSection";
import { UserReviewSection } from "../UserReviewSection";
import { WhyDNACanvasSection } from "../WhyDNACanvasSection";

export const HomePage: NextPage = () => {
  return (
    <MainLayout>
      <HeadingSection />
      <WhyDNACanvasSection />
      <BenefitsSection />
      <StepsSection />
      <PackageSection />
      <UserReviewSection />
      <PartnersSections />
      <QualityGuaranteedSection />
      <DownloadAppSection />
    </MainLayout>
  );
};
