import { Box, Paper, Typography } from "@mui/material";
import type { FC, ReactNode } from "react";
import { isFragment } from "react-is";
import { IconWrapper } from "./IconWrapper";

interface Props {
  icon: ReactNode;
  title: ReactNode;
  description: ReactNode;
}

export const WhyDNACanvasCard: FC<Props> = ({ icon, title, description }) => {
  const isTitleStringOrFragment = typeof title === "string" || isFragment(title);

  return (
    <Paper
      sx={{
        borderRadius: "16px 56px",
        border: "1px solid",
        borderColor: "warning.500",
        height: "auto",
      }}
    >
      <Box sx={{ p: 4 }}>
        <IconWrapper size={48} backgroundColor="transparent">
          {icon}
        </IconWrapper>
        {isTitleStringOrFragment ? (
          <Typography variant="caption3" color="text.darkblue" sx={{ mt: 3 }} whiteSpace="pre-line">
            {title}
          </Typography>
        ) : (
          title
        )}
        <Typography variant="body2" color="text.darkblue" sx={{ mt: 1 }} whiteSpace="pre-line">
          {description}
        </Typography>
      </Box>
    </Paper>
  );
};
