import { useMuiTheme } from "@/hooks/useMuiTheme";
import styled from "@emotion/styled";
import { Box, Button, Container, Typography, useMediaQuery } from "@mui/material";
import parse from "html-react-parser";
import NextImage from "next/image";
import { useTranslation } from "react-i18next";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import HeroBannerImage from "../../../public/assets/imgs/webp/headings/herobanner.webp";

export const HeadingSection = () => {
  const theme = useMuiTheme();
  const downSlg = useMediaQuery(theme.breakpoints.down("slg"));

  const { t, i18n } = useTranslation();

  return (
    <Box>
      <Box
        sx={{
          backgroundImage: `url('/assets/imgs/webp/headings/herobanner.webp'), linear-gradient(180deg, #F1D5BF 0%, #F3D7C2 100%)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "47vw auto, cover",
          backgroundPosition: "right center, left top",
          [theme.breakpoints.down("slg")]: {
            backgroundSize: "0 0, cover",
          },
        }}
      >
        <Container
          sx={{
            isolation: "isolate",
            position: "relative",
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            columnGap: 2,
            pt: {
              xs: 5,
              lg: 10,
            },
            pb: {
              xs: 0,
              // md: 5,
              lg: 15,
            },
            [theme.breakpoints.down("lg")]: {
              gridTemplateColumns: "1fr 1fr",
              columnGap: 0,
            },
            [theme.breakpoints.down("slg")]: {
              display: "flex",
              flexDirection: "column",
            },
          }}
          disableGutters={downSlg}
        >
          <Container
            sx={{
              gridColumn: "2 / span 7",
              [theme.breakpoints.down("2xl")]: {
                gridColumn: "1 / span 7",
              },
              [theme.breakpoints.down("lg")]: {
                gridColumn: "1 / span 1",
              },
              [theme.breakpoints.down("slg")]: {
                mb: 5,
              },
            }}
            disableGutters={!downSlg}
          >
            <Box
              width="fit-content"
              sx={{
                position: "relative",
                isolation: "isolate",
              }}
            >
              <Typography fontSize={24} fontWeight={600} lineHeight={"33px"} color="primary.800">
                DNAcanvas
              </Typography>
              <Typography
                component="h1"
                fontSize={48}
                lineHeight={1.17}
                fontWeight={500}
                variant="h2"
                sx={{
                  mt: 0.5,
                  mb: 1.5,
                  fontSize: { sm: 48, lineHeight: { sm: 1.17 }, fontWeight: { sm: 500 } },
                }}
              >
                {parse(t("home-heading.title"))}
              </Typography>
              <Typography
                color="translucent.black.56"
                sx={{
                  // display: "inline-block",
                  // wordBreak: "keep-all",
                  typography: {
                    xs: "caption3",
                    sm: "caption2",
                  },
                }}
              >
                {t("home-heading.subtitle")}
              </Typography>
              <Button
                component="a"
                href="#package"
                variant="contained"
                color="black"
                sx={{
                  display: "flex",
                  width: "fit-content",
                  px: "49.5px",
                  borderRadius: 999,
                  mt: 3,
                  mb: { xs: 2, sm: 3 },
                }}
              >
                {t("home-heading.buy-now")}
              </Button>
              {/* Decorations go here */}
              <Box
                component="img"
                src="/assets/imgs/webp/decorations/dna-spiral.webp"
                alt="DNA Spiral decoration image"
                sx={{
                  position: "absolute",
                  top: "0",
                  left: "100%",
                  transform: "translate(-50%, -30%)",
                  width: 185,
                  [theme.breakpoints.down("sm")]: {
                    width: 145,
                    transform: "translate(-80%, -30%)",
                  },
                }}
              />
              <Box
                component="img"
                src="/assets/imgs/webp/decorations/stethoscope.webp"
                alt="Stethoscope decoration image"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  transform: "translate(-80%, -50%)",
                  width: 185,
                  [theme.breakpoints.down("2xl")]: {
                    display: "none",
                  },
                }}
              />
              <Box
                component="img"
                src="/assets/imgs/webp/decorations/pills.webp"
                alt="Pills decoration image"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  transform: "translate(0, 50%)",
                  width: 185,
                  [theme.breakpoints.down("lg")]: {
                    width: 165,
                    transform: "translate(-30%, 30%)",
                  },
                  [theme.breakpoints.down("sm")]: {
                    transform: "translate(-20%, 20%)",
                    width: 103,
                  },
                }}
              />
            </Box>
            {/* <Stack direction="row" spacing={1} alignItems="flex-end">
              <Typography variant="caption5" color="translucent.black.56">
                Powered by
              </Typography>
              <Box
                component="img"
                src="/assets/imgs/webp/ais-startup.webp"
                sx={{
                  // width: "fit-content",
                  height: 28,
                  mixBlendMode: "multiply",
                  [theme.breakpoints.down("sm")]: {
                    height: 24,
                  },
                }}
              />
            </Stack> */}
          </Container>
          <Box
            sx={{
              width: "100vw",
              height: "100vw",
              position: "relative",
              display: "none",
              [theme.breakpoints.down("slg")]: {
                display: "block",
              },
            }}
          >
            <NextImage
              src={HeroBannerImage}
              alt="Hero banner image"
              fill
              placeholder="blur"
              loading="eager"
            />
            {/* <Box
              component="img"
              src="/assets/imgs/webp/headings/herobanner.webp"
              alt="Hero banner image"
              sx={{
                display: "none",
                width: "100%",
                maxWidth: "100%",
                // aspectRatio: "1 / 1",
                // objectFit: "contain",
                [theme.breakpoints.down("slg")]: {
                  display: "block",
                },
              }}
            /> */}
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          position: "relative",
          top: "-56px",
          mb: "-56px",
          pb: "32px",
          [theme.breakpoints.down("lg")]: {
            position: "static",
            mb: 0,
            py: 3,
          },
        }}
      >
        <Container sx={{ maxWidth: "100vw" }}>
          <StyledSwiper
            grabCursor={true}
            slidesPerView={1.1}
            spaceBetween={16}
            modules={[Pagination]}
            breakpoints={{
              [theme.breakpoints.values.md]: {
                slidesPerView: 2,
                grabCursor: false,
              },
            }}
            pagination={{
              enabled: true,
              clickable: true,
            }}
            style={{ overflow: "visible" }}
          >
            <SwiperSlide>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  paddingTop: "35.67%",
                  borderRadius: 16,
                  overflow: "hidden",
                }}
              >
                <NextImage
                  src={`/assets/imgs/webp/headings/banner-1_${i18n.language}.webp`}
                  alt="Homepage heading banner image 1"
                  fill
                  style={{ objectFit: "contain" }}
                />
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  paddingTop: "35.67%",
                  borderRadius: 16,
                  overflow: "hidden",
                }}
              >
                <NextImage
                  src={`/assets/imgs/webp/headings/banner-2_${i18n.language}.webp`}
                  alt="Homepage heading banner image 2"
                  fill
                  style={{ objectFit: "contain" }}
                />
              </Box>
            </SwiperSlide>
          </StyledSwiper>
        </Container>
      </Box>
    </Box>
  );
};

const StyledSwiper = styled(Swiper)`
  .swiper-pagination {
  }

  .swiper-pagination {
    width: fit-content;
    position: static;
    --swiper-pagination-color: ${({ theme }) => theme.palette.primary[600]};
    --swiper-pagination-bullet-width: 20px;
    --swiper-pagination-bullet-height: 3px;
    --swiper-pagination-bullet-border-radius: 0;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-inactive-color: ${({ theme }) => theme.palette.primary[200]};
    --swiper-pagination-bullet-active-opacity: 1;
    & > .swiper-pagination-bullet {
      left: 0;
      transition: all 0.3s ease;
      &:only-child {
        display: inline-block !important;
      }
    }
    &.swiper-pagination-lock {
      display: block;
    }
  }

  .custom-pagination-bullet {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 18px));
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 18px));
    display: inline-block;
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
    background: var(--swiper-pagination-bullet-inactive-color, #000);
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
  }

  .swiper-slide {
    max-width: 100vw;
    ${({ theme }) => theme.breakpoints.up("md")} {
      max-width: min(50vw, 656px);
    }
  }
`;
