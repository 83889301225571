import { Box, Typography } from "@mui/material";

import type { FC } from "react";

interface Props {
  image: string;
  title: string;
  description: string;
}
export const EndorsementCard: FC<Props> = ({ image, title, description }) => {
  return (
    <Box
      display="grid"
      sx={{
        p: 2,
        borderRadius: 16,
        backgroundColor: "white.main",
        boxShadow:
          "0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519)",
        gridTemplateColumns: "56px 1fr",
        columnGap: 2,
        gridTemplateRows: "auto auto",
        rowGap: 0.25,
        gridTemplateAreas: `
      'icon title'
      'icon description'
    `,
      }}
    >
      <Box
        component="img"
        src={image}
        alt={`organization logo`}
        sx={{ width: 56, gridArea: "icon", placeSelf: "center" }}
      />
      <Typography variant="caption4" color="translucent.black.88" sx={{ gridArea: "title" }}>
        {title}
      </Typography>
      <Typography variant="body3" color="translucent.black.88" sx={{ gridArea: "description" }}>
        {description}
      </Typography>
    </Box>
  );
};
