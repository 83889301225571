import { SectionSubtitle } from "@/components/SectionSubtitle";
import { SectionTitle } from "@/components/SectionTitle";
import { useMuiTheme } from "@/hooks/useMuiTheme";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useMemo, type FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {}
export const BenefitsSection: FC<Props> = () => {
  const theme = useMuiTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const { t, i18n } = useTranslation();

  const benefits = useMemo(
    () => [
      {
        image: "/assets/imgs/webp/drugs.webp",
        backgroundColor: "violet.100",
        label: t("home-benefit.nutrients"),
      },
      {
        image: "/assets/imgs/webp/food.webp",
        backgroundColor: "mint.100",
        label: t("home-benefit.food"),
      },
      {
        image: "/assets/imgs/webp/weighing-scale.webp",
        backgroundColor: "error.100",
        label: t("home-benefit.weight"),
      },
      {
        image: "/assets/imgs/webp/dish-grey.webp",
        backgroundColor: "warning.100",
        label: t("home-benefit.meal"),
      },
      {
        image: "/assets/imgs/webp/skincare.webp",
        backgroundColor: "success.100",
        label: t("home-benefit.beauty"),
      },
      {
        image: "/assets/imgs/webp/exercise.webp",
        backgroundColor: "info.200",
        label: t("home-benefit.concern"),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language],
  );

  return (
    <Box
      sx={{
        py: {
          xs: 10,
        },
      }}
    >
      <SectionTitle textAlign="center" sx={{ px: 2 }} whiteSpace="pre-line">
        {t("home-benefit.title", { lineBreak: isXs ? "\n" : "" })}
      </SectionTitle>
      <SectionSubtitle sx={{ mt: 1 }} textAlign="center">
        *{t("home-benefit.remark")}
      </SectionSubtitle>
      <Container
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          columnGap: 2,
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "repeat(4,1fr)",
          },
        }}
        // disableGutters={true}
        // disableGutters={upXl}
      >
        <Grid2
          container
          columnSpacing={4}
          rowSpacing={2}
          sx={{ mx: "auto", mt: 7, gridColumn: "1 / -1", width: "100%" }}
        >
          {benefits.map((benefit) => {
            return (
              <Grid2
                key={benefit.label}
                xs={6}
                md={4}
                lg={2}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gridTemplateRows: "104px 1fr",
                  rowGap: 2,
                  // placeContent: "center",
                  // maxWidth: 144,
                }}
              >
                <Box
                  sx={{
                    width: 104,
                    height: 104,
                    display: "grid",
                    placeContent: "center",
                    backgroundColor: benefit.backgroundColor,
                    borderRadius: 9999,
                    mx: "auto",
                  }}
                >
                  <Box
                    component="img"
                    src={benefit.image}
                    alt={`Benefit image for ${benefit.label}`}
                    sx={{
                      width: 71,
                      height: 71,
                    }}
                  />
                </Box>
                <Typography color="text.darkblue" textAlign="center" sx={{ verticalAlign: "top" }}>
                  {benefit.label}
                </Typography>
              </Grid2>
            );
          })}
        </Grid2>
      </Container>
    </Box>
  );
};

// save just in case

// {benefitsChunks.map((benefits, index) => {
//   return (
//     <Box
//       key={index}
//       width="100%"
//       display="flex"
//       justifyContent="space-between"
//       sx={{
//         gridColumn: "1 / -1",
//         mt: {
//           xs: 3,
//           md: 7,
//         },
//       }}
//     >
//       {benefits.map((benefit) => {
//         return (
//           <Box
//             key={benefit.label}
//             sx={{
//               width: 157,
//               display: "grid",
//               gridTemplateColumns: "1fr",
//               gridTemplateRows: "104px 1fr",
//               rowGap: 2,
//               // placeContent: "center",
//               // maxWidth: 144,
//               [theme.breakpoints.down("sm")]: {
//                 width: "100%",
//               },
//             }}
//           >
//             <Box
//               sx={{
//                 width: 104,
//                 height: 104,
//                 display: "grid",
//                 placeContent: "center",
//                 backgroundColor: benefit.backgroundColor,
//                 borderRadius: 9999,
//                 mx: "auto",
//               }}
//             >
//               <Box
//                 component="img"
//                 src={benefit.image}
//                 sx={{
//                   width: 71,
//                   height: 71,
//                 }}
//               />
//             </Box>
//             <Typography
//               color="text.darkblue"
//               textAlign="center"
//               sx={{ verticalAlign: "top" }}
//             >
//               {benefit.label}
//             </Typography>
//           </Box>
//         );
//       })}
//     </Box>
//   );
// })}
