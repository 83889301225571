import { useMuiTheme } from "@/hooks/useMuiTheme";
import { CheckSolidIcon } from "@/svgs";
import {
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const PremiumPackageCard = () => {
  const theme = useMuiTheme();
  const { t } = useTranslation();

  const featuresTranslation = t("package.premium.features", { returnObjects: true });
  const features = Array.isArray(featuresTranslation) ? featuresTranslation : [featuresTranslation];

  return (
    <Paper
      sx={{
        p: 4,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "auto",
        borderRadius: "8px 8px 48px 8px",
        position: "relative",
        overflow: "hidden",
        borderWidth: "0 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "divider",
        filter: theme.palette.filter.shadow200,
        transition: "filter 150ms ease-out",
        ":hover": {
          filter: theme.palette.filter.shadow400,
        },
        "::before": {
          content: '""',
          position: "absolute",
          inset: 0,
          bottom: "auto",
          height: 8,
          width: "100%",
          backgroundImage: "linear-gradient(267.37deg, #F12711 -79.32%, #F5AF19 106.34%)",
        },
      }}
    >
      <Stack spacing={0.5}>
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="flex-start">
          <Typography variant="caption3" color="text.darkblue">
            {t("package.premium.name")}
          </Typography>
          <Chip
            label={
              <Typography variant="label3" color="text.white">
                Valuable
              </Typography>
            }
            sx={{
              py: 0,
              height: "auto",
              background: "linear-gradient(267.37deg, #F12711 -79.32%, #F5AF19 106.34%)",
            }}
          />
        </Box>
        <Typography variant="h2" color="text.darkblue">
          <Typography component="span" variant="inherit" fontWeight={400}>
            ฿
          </Typography>
          <Typography component="span" variant="inherit">
            12,900
          </Typography>
        </Typography>
        <Typography variant="body2" color="translucent.black.56">
          <Typography component="span" variant="inherit">
            {t("from")}{" "}
          </Typography>
          <Typography component="span" variant="inherit">
            ฿15,900
          </Typography>
          {/* <Typography component="span" variant="inherit">
            บาท
          </Typography> */}
        </Typography>
      </Stack>
      <Typography
        variant="caption4"
        color="text.black"
        sx={{
          mt: 2,
        }}
        whiteSpace="pre-line"
      >
        {t("package.premium.description")}
      </Typography>
      <Divider sx={{ my: 2 }} />
      <List>
        {features.map((feature, idx) => {
          const isString = typeof feature === "string";
          const bullets: string[] =
            !isString && Array.isArray(feature.bullets) ? feature.bullets : [];
          return (
            <ListItem key={`premium-features-${idx + 1}`} alignItems="flex-start">
              <ListItemIcon sx={{ color: "success.main", mt: 0 }}>
                <CheckSolidIcon width={32} height={32} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">{isString ? feature : feature.text}</Typography>
                {/* {isString ? <Typography variant="body2">{feature}</Typography> : feature} */}
                {bullets.map((bullet, idx) => {
                  return (
                    <Box
                      key={idx}
                      // disablePadding
                      sx={{
                        display: "list-item",
                        marginInlineStart: 1.5,
                        position: "relative",
                        ":before": {
                          content: "'•'",
                          position: "absolute",
                        },
                      }}
                    >
                      <Typography variant="body2" sx={{ marginInlineStart: 2 }}>
                        {bullet}
                      </Typography>
                    </Box>
                  );
                })}
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
      <Box pt={4} mt="auto">
        <Button
          component="a"
          href="https://lin.ee/2AwJa7x"
          target="_blank"
          rel="noreferrer nofollow"
          variant="contained"
          color="black"
          fullWidth
          sx={{ borderRadius: 999 }}
        >
          {t("package.premium.button-text")}
        </Button>
      </Box>
    </Paper>
  );
};
