import { Prompt } from "next/font/google";

export const prompt = Prompt({
  variable: "--font-prompt",
  weight: ["400", "600"],
  style: ["normal"],
  display: "swap",
  subsets: ["thai", "latin"],
  preload: true,
});

export default prompt;
