import { TranslationContext } from "@/providers/TranslationProvider";
import { Box, Chip, Typography, styled } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const StyledChip = styled(Chip, { shouldForwardProp: (props) => props !== "active" })<{
  active: boolean;
}>(({ theme, active }) => ({
  boxShadow: `${active}`,
  padding: theme.spacing(0.5, 1.25),
  height: "auto",
  backgroundColor: active ? theme.palette.common.white : "transparent",
  transition: theme.transitions.create("background-color", { easing: "ease-out", duration: 200 }),
  "&.MuiButtonBase-root": {
    ":hover": {
      backgroundColor: active ? theme.palette.common.white : "transparent",
    },
    ":active": {
      boxShadow: "none",
    },
  },
  ".MuiChip-label": {
    color: active ? theme.palette.tertiary["600"] : theme.palette.common.white,
    lineHeight: 1,
    transition: theme.transitions.create("color", { easing: "ease-out", duration: 200 }),
  },
  ".MuiTouchRipple-root": {
    display: "none",
  },
}));

export const SwitchLanguageButton = () => {
  const { i18n } = useTranslation();

  const { setLanguage } = useContext(TranslationContext);

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      columnGap={0.25}
      sx={{
        width: 80,
        p: 0.25,
        borderRadius: 999,
        backgroundColor: "tertiary.main",
      }}
    >
      <StyledChip
        label={
          <Typography variant="label3" color="inherit">
            TH
          </Typography>
        }
        active={i18n.language === "th"}
        clickable
        onClick={() => {
          setLanguage("th");
        }}
      />
      <StyledChip
        label={
          <Typography variant="label3" color="inherit">
            EN
          </Typography>
        }
        active={i18n.language === "en"}
        clickable
        onClick={() => {
          setLanguage("en");
        }}
      />
    </Box>
  );
};
