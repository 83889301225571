import { SectionSubtitle } from "@/components/SectionSubtitle";
import { SectionTitle } from "@/components/SectionTitle";
import { UserReviewCard } from "@/components/UserReviewCard";
import { useForceReRender } from "@/hooks/useForceReRender";
import { useMuiTheme } from "@/hooks/useMuiTheme";
import { ChevronLeftIcon, ChevronRightIcon } from "@/svgs";
import { Box, Button, Container, Stack, useMediaQuery } from "@mui/material";
import { useRef, useState, type FC, type RefObject } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide, type SwiperRef } from "swiper/react";
import { type Swiper as SwiperInstance } from "swiper/types";

interface Props {}

export const UserReviewSection: FC<Props> = () => {
  const { t } = useTranslation();
  const theme = useMuiTheme();

  const upXl = useMediaQuery(theme.breakpoints.up("xl"));

  const reRender = useForceReRender();

  const ref: RefObject<SwiperRef> = useRef(null);

  const [swiperInstance, setSwiperInstance] = useState<SwiperInstance>();

  return (
    <Box
      sx={{
        py: 8,
        backgroundColor: "tertiary.200",
        borderRadius: "240px 0 80px",
      }}
    >
      <Container
        sx={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)", columnGap: 2 }}
        disableGutters={upXl}
      >
        <Box
          sx={{
            gridColumn: "2 / span 10",
            [theme.breakpoints.down("xl")]: {
              gridColumn: "span 12",
            },
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <SectionSubtitle>{t("home-review.subtitle")}</SectionSubtitle>
              <SectionTitle sx={{ mt: 1 }}>{t("home-review.title")}</SectionTitle>
            </Box>
            <Stack direction="row" spacing={2}>
              <Button
                aria-label="Previous testimonial"
                onClick={() => {
                  swiperInstance?.slidePrev?.();
                }}
                disabled={swiperInstance?.isBeginning}
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: 12,
                  backgroundColor: "white.main",
                  color: "translucent.black.88",
                  ":hover": {
                    backgroundColor: "white.main",
                    boxShadow: theme.palette.boxShadow[200],
                  },
                }}
              >
                <ChevronLeftIcon width={32} height={32} />
              </Button>
              <Button
                aria-label="Next testimonial"
                onClick={() => {
                  swiperInstance?.slideNext?.();
                }}
                disabled={swiperInstance?.isEnd}
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: 12,
                  backgroundColor: "white.main",
                  color: "translucent.black.88",
                  ":hover": {
                    backgroundColor: "white.main",
                    boxShadow: theme.palette.boxShadow[200],
                  },
                }}
              >
                <ChevronRightIcon width={32} height={32} />
              </Button>
            </Stack>
          </Box>
        </Box>
      </Container>
      <Container
        // maxWidth="xs"
        // sx={{
        //   mt: 5,
        //   maxWidth: {
        //     xs: "100vw",
        //     "2xl": "2xl",
        //   },
        // }}
        sx={{
          mt: 5,
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          columnGap: 2,
          overflow: "visible",
        }}
        disableGutters={upXl}
      >
        <Box
          sx={{
            gridColumn: "2 / span 11",
            [theme.breakpoints.down("xl")]: {
              gridColumn: "span 12",
            },
          }}
        >
          <Swiper
            ref={ref}
            grabCursor={true}
            slidesPerView={"auto"}
            // slidesPerView={1.25}
            spaceBetween={24}
            // centeredSlides={true}
            // centeredSlidesBounds={true}
            // breakpoints={{
            //   480: {
            //     slidesPerView: 1.5,
            //   },
            //   [theme.breakpoints.values.sm]: {
            //     slidesPerView: 2.5,
            //   },
            //   [theme.breakpoints.values.md]: {
            //     slidesPerView: 3.5,
            //   },
            //   [theme.breakpoints.values.lg]: {
            //     slidesPerView: 4.25,
            //   },
            //   [theme.breakpoints.values.xl]: {
            //     slidesPerView: 4.33,
            //   },
            // }}
            style={{ overflow: "visible" }}
            onSwiper={setSwiperInstance}
            onTransitionEnd={() => {
              reRender();
            }}
            onTransitionStart={() => {}}
          >
            {Array.from({ length: 5 }).map((review, idx) => {
              const imgKey = `home-review.reviews.${idx}.image`;
              const nameKey = `home-review.reviews.${idx}.name`;
              const textKey = `home-review.reviews.${idx}.text`;
              const occupationsKey = `home-review.reviews.${idx}.occupations`;
              const ageKey = `home-review.reviews.${idx}.age`;
              return (
                <SwiperSlide key={idx} style={{ width: "fit-content" }}>
                  <UserReviewCard
                    image={t(imgKey)}
                    name={t(nameKey)}
                    text={t(textKey)}
                    occupations={t(occupationsKey, { returnObjects: true })}
                    age={t(ageKey) as unknown as number}
                    sx={{ width: 270 }}
                  />
                </SwiperSlide>
              );
            })}
            {/* <SwiperSlide>
              <UserReviewCard
                image="/assets/imgs/webp/reviewers/reviewer1.webp"
                name="ตุ๊กตา"
                review="ตรวจครั้งเดียวใช้ผลได้ตลอดชีวิต คุ้มมากๆ"
                occupation={[t("occupation.pharmacist")]}
                age={25}
              />
            </SwiperSlide>
            <SwiperSlide>
              <UserReviewCard
                image="/assets/imgs/webp/reviewers/reviewer2.webp"
                name="สาริศ"
                review="DNAcanvas ช่วยจัดการความเสี่ยง ตั้งแต่ยังสุขภาพดี ผลอ่านเข้าใจง่าย ได้มาตรฐาน"
                occupation={[t("occupation.medicalStudent")]}
                age={26}
              />
            </SwiperSlide>
            <SwiperSlide>
              <UserReviewCard
                image="/assets/imgs/webp/reviewers/reviewer3.webp"
                name="ภัทราภรณ์"
                review="วางแผนชีวิตได้ ทั้งตัวเอง และคนที่เรารัก"
                occupation={[t("occupation.teacher")]}
                age={32}
              />
            </SwiperSlide>
            <SwiperSlide>
              <UserReviewCard
                image="/assets/imgs/webp/reviewers/reviewer4.webp"
                name="เอิร์น"
                review="แม่นยำมากถึง 99% ช่วยปลดล็อค ศักยภาพการวิ่งของเราให้ดีมากขึ้น"
                occupation={[t("occupation.academician"), t("occupation.angelRunner")]}
                age={30}
              />
            </SwiperSlide>
            <SwiperSlide>
              <UserReviewCard
                image="/assets/imgs/webp/reviewers/reviewer5.webp"
                name="บัว ทอฝัน"
                review="ดีมากจริงๆ จนอยากบอกต่อ ใช้ง่ายมากๆ"
                occupation={[t("occupation.model", { context: "female" })]}
                age={25}
              />
            </SwiperSlide> */}
          </Swiper>
        </Box>
      </Container>
    </Box>
  );
};
