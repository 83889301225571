import { IconWrapper } from "@/components/IconWrapper";
import { WhyDNACanvasCard } from "@/components/WhyDNACanvasCard";
import { useMuiTheme } from "@/hooks/useMuiTheme";
import {
  ArrowRightIcon,
  WhyDNAClocheIcon,
  WhyDNADNAIcon,
  WhyDNAHeartIcon,
  WhyDNAPillIcon,
} from "@/svgs";
import { Box, Button, Container, Link, Typography, styled } from "@mui/material";
import parse from "html-react-parser";
import NextLink from "next/link";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";

const StyledSwiper = styled(Swiper)`
  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    display: flex;
    height: auto;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    overflow: visible;
  }
`;

export const WhyDNACanvasSection = () => {
  const theme = useMuiTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to right, ${theme.palette.warning[100]} 0% 50%, transparent 50% 100%)`,
      }}
    >
      <Container
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(11, 1fr) repeat(2, 0.5fr)",
        }}
      >
        <Box
          sx={{
            gridColumnStart: 2,
            gridColumnEnd: -2,
            display: "grid",
            gridTemplateColumns: "repeat(21, 1fr)",
            borderTopRightRadius: 120,
            borderBottomRightRadius: 16,
            backgroundColor: "warning.100",
            [theme.breakpoints.down("2xl")]: {
              gridColumnStart: 1,
            },
          }}
        >
          <Box
            sx={{
              gridColumnStart: 1,
              gridColumnEnd: "span 20",
              pt: 8,
              pb: {
                xs: 5.25,
                md: 6,
                xl: 6.5,
              },
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h2">{t("home-why.title")}</Typography>
              <NextLink href="/why-dnacanvas" legacyBehavior passHref>
                <Button
                  variant="text"
                  endIcon={
                    <IconWrapper backgroundColor="transparent" size={20}>
                      <ArrowRightIcon width={20} height={20} />
                    </IconWrapper>
                  }
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      display: "none",
                    },
                  }}
                >
                  <Link component="span" variant="label1" color="primary.600">
                    {t("home-why.read-all")}
                  </Link>
                </Button>
              </NextLink>
            </Box>
            <Box mt={7}>
              <StyledSwiper
                grabCursor={true}
                slidesPerView={1.3}
                centeredSlides={false}
                breakpoints={{
                  480: {
                    slidesPerView: 1.5,
                  },
                  [theme.breakpoints.values.sm]: {
                    slidesPerView: 1.75,
                  },
                  [theme.breakpoints.values.md]: {
                    slidesPerView: 2.3,
                  },
                  [theme.breakpoints.values.lg]: {
                    slidesPerView: 3.3,
                  },
                  [theme.breakpoints.values.xl]: {
                    slidesPerView: 4,
                    grabCursor: false,
                  },
                }}
                spaceBetween={32}
              >
                <SwiperSlide>
                  <WhyDNACanvasCard
                    icon={<WhyDNAClocheIcon width={48} height={48} />}
                    title={t("home-why.reasons.0.title")}
                    description={parse(t("home-why.reasons.0.description"))}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <WhyDNACanvasCard
                    icon={<WhyDNAPillIcon width={48} height={48} />}
                    title={t("home-why.reasons.1.title")}
                    description={t("home-why.reasons.1.description")}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <WhyDNACanvasCard
                    icon={<WhyDNAHeartIcon width={48} height={48} />}
                    title={t("home-why.reasons.2.title")}
                    description={t("home-why.reasons.2.description")}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <WhyDNACanvasCard
                    icon={<WhyDNADNAIcon width={48} height={48} />}
                    title={t("home-why.reasons.3.title")}
                    description={t("home-why.reasons.3.description")}
                  />
                </SwiperSlide>
              </StyledSwiper>
            </Box>
            <Box width="100%" display="flex" justifyContent="flex-end" mt={4}>
              <NextLink href="/why-dnacanvas" legacyBehavior passHref>
                <Button
                  variant="text"
                  endIcon={
                    <IconWrapper backgroundColor="transparent" size={20}>
                      <ArrowRightIcon width={20} height={20} />
                    </IconWrapper>
                  }
                  sx={{
                    display: "none",
                    [theme.breakpoints.down("md")]: {
                      display: "inline-flex",
                    },
                  }}
                >
                  <Typography variant="label1" color="primary.600">
                    {t("home-why.read-all")}
                  </Typography>
                </Button>
              </NextLink>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
