import { Box } from "@mui/material";

import type { BoxProps } from "@mui/material";
import type { FC, PropsWithChildren } from "react";

interface Props extends BoxProps {
  size?: number;
  radius?: number;
  rounded?: boolean | number;
  center?: boolean;
  backgroundColor?: string;
}

export const IconWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  size = 32,
  rounded = false,
  center = true,
  backgroundColor = "background.default",
  ...props
}) => {
  return (
    <Box
      {...props}
      sx={{
        width: size,
        height: size,
        backgroundColor,
        ...(rounded && {
          borderRadius: typeof rounded === "number" ? rounded : 9999,
        }),
        ...(center && {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }),
        ...props?.sx,
      }}
    >
      {children}
    </Box>
  );
};
