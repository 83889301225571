import { Button, Typography } from "@mui/material";
import NextLink from "next/link";
import { type FC } from "react";

interface Props {
  href: string;
  label: string;
  noBorder?: boolean;
}
export const NavbarLinkButton: FC<Props> = ({ href, label, noBorder }) => {
  return (
    <NextLink href={href} legacyBehavior passHref>
      <Button
        sx={{
          height: 40,
          position: "relative",
          color: "translucent.black.88",
          transition: "color 100ms ease-in-out",
          ":hover, :focus, :active": {
            color: "tertiary.600",
            ":after": {
              transform: "scaleX(1)",
            },
          },
          ":after": {
            display: noBorder ? "none" : "block",
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "1px",
            backgroundColor: "tertiary.600",
            transform: "scaleX(0)",
            transformOrigin: "center",
            transition: "transform 100ms ease-in-out",
          },
        }}
        disableFocusRipple
      >
        <Typography variant="label2" color="inherit">
          {label}
        </Typography>
      </Button>
    </NextLink>
  );
};
