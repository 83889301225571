import styled from "@emotion/styled";
import { Box, Container, Stack, Typography, useMediaQuery } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import { Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { SectionSubtitle } from "@/components/SectionSubtitle";
import { SectionTitle } from "@/components/SectionTitle";
import { useMuiTheme } from "@/hooks/useMuiTheme";
import type { RefObject } from "react";
import { useTranslation } from "react-i18next";
import type { SwiperRef } from "swiper/react";

const StyledStepWrapper = styled(Box, { shouldForwardProp: (props) => props !== "active" })<{
  active: boolean;
}>(({ theme, active }) => ({
  display: "grid",
  gridTemplateColumns: "56px 1fr",
  gridTemplateRows: "1fr 1ft",
  rowGap: theme.spacing(0.5),
  columnGap: theme.spacing(3),
  padding: theme.spacing(2),
  borderRadius: 9999,
  background: active
    ? "linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)"
    : "transparent",
  "& > .StyledStepNumber": {
    background: active
      ? "linear-gradient(180deg, #F9D8C6 0%, #FEF5EC 100%)"
      : theme.palette.common.white,
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1.5, 2),
  },
}));

const StyledStepNumber = styled(Box, {
  target: "StyledStepNumber",
})(() => ({
  gridRow: "1 / span 2",
  display: "grid",
  placeContent: "center",
  placeSelf: "center",
  width: 56,
  height: 56,
  borderRadius: 56,
}));

export const StepsSection = () => {
  const swiperRef: RefObject<SwiperRef> = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const theme = useMuiTheme();

  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        py: 8,
      }}
    >
      <Container
        sx={{
          overflow: "visible",
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          columnGap: 2,
        }}
        disableGutters={downLg}
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr 32px 1fr"
          columnGap={0}
          position="relative"
          sx={{
            isolation: "isolate",
            gridColumn: "2 / span 10",
            [theme.breakpoints.down("2xl")]: {
              gridColumn: "span 12",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              gridColumn: "1 / span 2",
              [theme.breakpoints.down("lg")]: {
                position: "relative",
                gridColumn: "1 / -1",
              },
            }}
          >
            <Box px={{ xs: 2, lg: 0 }}>
              <SectionSubtitle>{t("home-steps.subtitle")}</SectionSubtitle>
              <SectionTitle sx={{ mt: 1 }}>{t("home-steps.title")}</SectionTitle>
            </Box>
            <Stack
              spacing={2}
              sx={{
                width: "100%",
                height: "auto",
                backgroundColor: "tertiary.100",
                borderRadius: "16px 80px",
                py: {
                  xs: 3,
                  lg: 5,
                },
                px: {
                  xs: 2,
                  md: 4,
                  lg: 6,
                },
                mt: {
                  xs: 3,
                  lg: 5,
                },
              }}
              onMouseEnter={() => {
                swiperRef.current?.swiper.autoplay.stop();
              }}
              onMouseLeave={() => {
                swiperRef.current?.swiper.autoplay.start();
              }}
            >
              <StyledStepWrapper
                active={activeIndex === 0}
                onClick={() => {
                  swiperRef?.current?.swiper.slideTo(0);
                }}
                onMouseEnter={() => {
                  swiperRef?.current?.swiper.slideTo(0);
                }}
              >
                <StyledStepNumber>
                  <Typography variant="h3" color="translucent.black.88">
                    1
                  </Typography>
                </StyledStepNumber>
                <Typography variant="h4" color="primary.800">
                  {t("home-steps.step1.title")}
                </Typography>
                <Typography variant="body2" color="translucent.black.88">
                  {t("home-steps.step1.description")}
                </Typography>
              </StyledStepWrapper>
              <StyledStepWrapper
                active={activeIndex === 1}
                onClick={() => {
                  swiperRef?.current?.swiper.slideTo(1);
                }}
                onMouseEnter={() => {
                  swiperRef?.current?.swiper.slideTo(1);
                }}
              >
                <StyledStepNumber>
                  <Typography variant="h3" color="translucent.black.88">
                    2
                  </Typography>
                </StyledStepNumber>
                <Typography variant="h4" color="primary.800">
                  {t("home-steps.step2.title")}
                </Typography>
                <Typography variant="body2" color="translucent.black.88">
                  {t("home-steps.step2.description")}
                </Typography>
              </StyledStepWrapper>
              <StyledStepWrapper
                active={activeIndex === 2}
                onClick={() => {
                  swiperRef?.current?.swiper.slideTo(2);
                }}
                onMouseEnter={() => {
                  swiperRef?.current?.swiper.slideTo(2);
                }}
              >
                <StyledStepNumber>
                  <Typography variant="h3" color="translucent.black.88">
                    3
                  </Typography>
                </StyledStepNumber>
                <Typography variant="h4" color="primary.800">
                  {t("home-steps.step3.title")}
                </Typography>
                <Typography variant="body2" color="translucent.black.88">
                  {t("home-steps.step3.description")}
                </Typography>
              </StyledStepWrapper>
              <StyledStepWrapper
                active={activeIndex === 3}
                onClick={() => {
                  swiperRef?.current?.swiper.slideTo(3);
                }}
                onMouseEnter={() => {
                  swiperRef?.current?.swiper.slideTo(3);
                }}
              >
                <StyledStepNumber>
                  <Typography variant="h3" color="translucent.black.88">
                    4
                  </Typography>
                </StyledStepNumber>
                <Typography variant="h4" color="primary.800">
                  {t("home-steps.step4.title")}
                </Typography>
                <Typography variant="body2" color="translucent.black.88">
                  {t("home-steps.step4.description")}
                </Typography>
              </StyledStepWrapper>
            </Stack>
            {downLg && (
              <Fragment>
                <Box
                  sx={{
                    backgroundColor: "secondary.main",
                    width: 96,
                    height: 96,
                    borderRadius: "0 50% 0 50%",
                    position: "absolute",
                    left: "50%",
                    transform: "translate(-100%, 0)",
                    bottom: -75,
                  }}
                />
                <Box
                  sx={{
                    backgroundColor: "info.200",
                    width: 48,
                    height: 48,
                    borderRadius: "50%",
                    position: "absolute",
                    right: "50%",
                    transform: "translate(100%, 0)",
                    bottom: -27,
                  }}
                />
              </Fragment>
            )}
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              background: "transparent",
              gridColumn: "2 / span 2",
              [theme.breakpoints.down("lg")]: {
                display: "none",
              },
              inset: 0,
              display: "flex",
            }}
          >
            <Swiper
              ref={swiperRef}
              effect="fade"
              modules={[Autoplay, EffectFade]}
              autoplay={{
                delay: 3000,
                pauseOnMouseEnter: true,
              }}
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.activeIndex);
              }}
              style={{ margin: "auto 0" }}
            >
              <SwiperSlide>
                <Box
                  component="img"
                  src={`/assets/imgs/webp/steps/step1.webp`}
                  alt="Step 1 image"
                  sx={{ width: "100%", maxWidth: 608 }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Box
                  component="img"
                  src={`/assets/imgs/webp/steps/step2.webp`}
                  alt="Step 2 image"
                  sx={{ width: "100%", maxWidth: 608 }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Box
                  component="img"
                  src={`/assets/imgs/webp/steps/step3.webp`}
                  alt="Step 3 image"
                  sx={{ width: "100%", maxWidth: 608 }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Box
                  component="img"
                  src={`/assets/imgs/webp/steps/step4.webp`}
                  alt="Step 4 image"
                  sx={{ width: "100%", maxWidth: 608 }}
                />
              </SwiperSlide>
            </Swiper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
