import { useMuiTheme } from "@/hooks/useMuiTheme";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";

export const PartnersSections = () => {
  const { t } = useTranslation();
  const theme = useMuiTheme();
  const down2Xl = useMediaQuery(theme.breakpoints.down("2xl"));
  return (
    <Box
      sx={{
        py: 8,
      }}
    >
      <Container maxWidth="2xl" sx={{ maxWidth: "100vw" }} disableGutters>
        <Typography variant="caption3" textAlign="center" color="translucent.black.56">
          {t("home-partner.title")}
        </Typography>
        <Box
          sx={{
            mt: 7,
            [theme.breakpoints.down("2xl")]: {
              display: "none",
            },
          }}
        >
          <Box display="grid" gridTemplateColumns="repeat(13, minmax(40px, 1fr))" columnGap={3}>
            {Array.from({ length: 13 }).map((_, index) => {
              const fileNum = (index + 1).toString().padStart(2, "0");
              return (
                <Box
                  key={index}
                  component="img"
                  src={`/assets/imgs/webp/partners/Partner${fileNum}.webp`}
                  alt={`Partner logo number ${fileNum}`}
                  sx={{ width: "100%", height: "auto" }}
                />
              );
            })}
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="repeat(13, minmax(40px, 1fr))"
            columnGap={3}
            mt={3}
          >
            {Array.from({ length: 13 }).map((_, index) => {
              const fileNum = (index + 14).toString().padStart(2, "0");
              return (
                <Box
                  key={index}
                  component="img"
                  src={`/assets/imgs/webp/partners/Partner${fileNum}.webp`}
                  alt={`Partner logo number ${fileNum}`}
                  sx={{ width: "100%", height: "auto" }}
                />
              );
            })}
          </Box>
        </Box>
        {down2Xl && (
          <Box sx={{ mt: 7 }}>
            <Marquee speed={30} gradient={false} gradientWidth={40}>
              {Array.from({ length: 13 }).map((_, index) => {
                const fileNum = (index + 1).toString().padStart(2, "0");
                return (
                  <Box
                    key={index}
                    component="img"
                    src={`/assets/imgs/webp/partners/Partner${fileNum}.webp`}
                    alt={`Partner logo number ${fileNum}`}
                    sx={{ width: 80, height: 80, mx: 1.5 }}
                  />
                );
              })}
            </Marquee>
            <Marquee speed={30} gradient={false} gradientWidth={40}>
              {Array.from({ length: 13 }).map((_, index) => {
                const fileNum = (index + 14).toString().padStart(2, "0");
                return (
                  <Box
                    key={index}
                    component="img"
                    src={`/assets/imgs/webp/partners/Partner${fileNum}.webp`}
                    alt={`Partner logo number ${fileNum}`}
                    sx={{ width: 80, height: 80, mx: 1.5, mt: 3 }}
                  />
                );
              })}
            </Marquee>
          </Box>
        )}
      </Container>
    </Box>
  );
};
